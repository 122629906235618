import { useTranslation } from "react-i18next";
import { Main } from "../Main/Main";
import {
  Features,
  Hero,
  HowItWorks,
  IncreaseConsumption,
  Terminals,
  ReadyToStart,
  Clients,
} from "./components";
import { useDynamicResolution } from "../../hooks";

const Landing = () => {
  const { t } = useTranslation();
  const isDesktop: boolean = useDynamicResolution();

  return (
    <Main isDesktop={isDesktop} isLanding t={t}>
      <>
        <Hero t={t} isDesktop={isDesktop} />
        <Clients t={t} />
        <IncreaseConsumption t={t} />
        <HowItWorks t={t} />
        <Terminals t={t} />
        {/* <ReverseATM t={t} isDesktop={isDesktop} /> */}
        <Features t={t} />
        <ReadyToStart t={t} isDesktop={isDesktop} />
      </>
    </Main>
  );
};

export default Landing;
