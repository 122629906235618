import { FC } from "react";
import Astros from "../../../resources/logos/clients/astros.png";
import AbiertoGnp from "../../../resources/logos/clients/abierto-gnp.png";
import AuditorioExplanada from "../../../resources/logos/clients/auditorio-explanada.png";
import WtaGdl from "../../../resources/logos/clients/wta-gdl.png";
import Chivas from "../../../resources/logos/clients/chivas.png";
import Otonobierfest from "../../../resources/logos/clients/otonobierfest.png";
import WtaMerida from "../../../resources/logos/clients/wta-merida.png";
import PashMusicHall from "../../../resources/logos/clients/pash-music-hall.png";
import ChefFestivalSonora from "../../../resources/logos/clients/chef-festival-sonora.png";
import Dreamfields from "../../../resources/logos/clients/dreamfields.png";
import Calaverandia from "../../../resources/logos/clients/calaverandia.png";
import Navidalia from "../../../resources/logos/clients/navidalia.png";
import Naranjeros from "../../../resources/logos/clients/naranjeros.png";
import Soccerlife from "../../../resources/logos/clients/soccerlife.png";
import { SectionProps } from "../../../interfaces";

export const Clients: FC<SectionProps> = ({ t }) => {
  return (
    <section className="bg-[#FDFCFF] py-16 px-[5%] sm:px-[3%]">
      <div className="max-w-[80rem] mx-auto">
        <h2 className="text-2xl/relaxed lg:text-3xl/relaxed font-medium text-center">
          {t("home.theyAlreadyLiveRealCashless")}
        </h2>
        <div className="w-full flex pt-10 overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_white_128px,_white_calc(100%-128px),transparent_100%)]">
          <div className="flex [&_img]:mx-8 [&_img]:object-contain [&_img]:max-w-none animate-infinite-scroll">
            <img src={Astros} alt="Astros logo" />
            <img src={AbiertoGnp} alt="Abierto GNP logo" />
            <img src={AuditorioExplanada} alt="Auditorio Explanada logo" />
            <img src={WtaGdl} alt="WTA GDL logo" />
            <img src={Chivas} alt="Club Chivas logo" />
            <img src={Otonobierfest} alt="Otoñobierfest logo" />
            <img src={WtaMerida} alt="WTA Merida logo" />
            <img src={PashMusicHall} alt="Pash Music Hall logo" />
            <img src={ChefFestivalSonora} alt="Festival del Chef Sonora logo" />
            <img src={Dreamfields} alt="Dreamfields logo" />
            <img src={Calaverandia} alt="Calaverandia logo" />
            <img src={Navidalia} alt="Navidalia logo" />
            <img src={Naranjeros} alt="Naranjeros logo" />
            <img src={Soccerlife} alt="Soccerlife logo" />
          </div>
          <div
            className="flex [&_img]:mx-8 [&_img]:object-contain [&_img]:max-w-none animate-infinite-scroll"
            aria-hidden="true"
          >
            <img src={Astros} alt="Astros logo" />
            <img src={AbiertoGnp} alt="Abierto GNP logo" />
            <img src={AuditorioExplanada} alt="Auditorio Explanada logo" />
            <img src={WtaGdl} alt="WTA GDL logo" />
            <img src={Chivas} alt="Club Chivas logo" />
            <img src={Otonobierfest} alt="Otoñobierfest logo" />
            <img src={WtaMerida} alt="WTA Merida logo" />
            <img src={PashMusicHall} alt="Pash Music Hall logo" />
            <img src={ChefFestivalSonora} alt="Festival del Chef Sonora logo" />
            <img src={Dreamfields} alt="Dreamfields logo" />
            <img src={Calaverandia} alt="Calaverandia logo" />
            <img src={Navidalia} alt="Navidalia logo" />
            <img src={Naranjeros} alt="Naranjeros logo" />
            <img src={Soccerlife} alt="Soccerlife logo" />
          </div>
        </div>
      </div>
    </section>
  );
};
