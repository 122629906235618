import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { Bars3Icon } from "@heroicons/react/24/solid";
import Yoshi from "../../../../resources/logos/yoshicash.png";
import YoshiLight from "../../../../resources/logos/yoshicash-light.png";
import { MobileMenu } from "./components";
import { TopbarMobileProps } from "../../interfaces";

export const TopbarMobile: FC<TopbarMobileProps> = (props) => {
  const { isDarkTheme, isLanding, t } = props;
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  return (
    <>
      <div
        className={`${
          isDarkTheme ? "bg-[#0F172A]" : "bg-white"
        } w-full md:hidden flex justify-between items-center h-[87px] px-[3%] fixed top-0 left-0 z-[1000]`}
      >
        <Link to="/" className="flex items-center">
          {isDarkTheme ? (
            <img
              src={YoshiLight}
              className="shrink-0 h-8 px-0.5"
              alt="Yoshi logo"
            />
          ) : (
            <img src={Yoshi} className="shrink-0 h-8 px-0.5" alt="Yoshi logo" />
          )}
        </Link>
        <button
          id="toggle-menu"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label="toggle-menu"
        >
          <Bars3Icon
            className={`h-7 w-7 ${
              isDarkTheme ? "text-white" : "text-gray-900"
            }`}
          />
        </button>
      </div>
      <MobileMenu
        isDarkTheme={isDarkTheme}
        isLanding={isLanding}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        t={t}
      />
    </>
  );
};
