import { FC } from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import PriceIcon from "../../../resources/icons/icon-price.svg";
import SwipeMachineIcon from "../../../resources/icons/icon-swipe-machine.svg";
import { SectionProps } from "../../../interfaces";

export const ReadyToStart: FC<SectionProps> = (props) => {
  const { t, isDesktop } = props;

  return (
    <section className="bg-white py-16 lg:py-24 px-[5%] sm:px-[3%]">
      <div className="max-w-[80rem] mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-16 lg:gap-20 xl:gap-24">
          <div className="flex flex-col gap-6">
            <h2 className="text-2xl/relaxed lg:text-3xl/relaxed font-medium">
              {t("home.readyToStart")}
            </h2>
            <p className="text-base/relaxed lg:text-lg/relaxed text-gray-400">
              {t("home.findOutHowYoshi")}
            </p>
            <a
              id={`i-want-to-know-more-${isDesktop ? "desktop" : "mobile"}`}
              href="https://form.typeform.com/to/vk1tRebX"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full px-5 py-3 bg-primary hover:bg-primary/80 text-white flex justify-center items-center gap-1.5 rounded-xl"
            >
              <span className="text-sm">{t("home.iWantToKnowMore")}</span>
              <ChevronRightIcon className="mt-0.5 size-3.5 stroke-[3]" />
            </a>
          </div>
          <div className="flex flex-col gap-3">
            <img
              loading="lazy"
              className="size-12"
              src={PriceIcon}
              alt="Price icon"
            />
            <h3 className="text-xl/relaxed lg:text-2xl/relaxed font-medium">
              {t("home.transactionFee")}
            </h3>
            <p className="text-base/relaxed lg:text-lg/relaxed text-gray-400">
              {t("home.payAFeeForEachTransaction")}
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <img
              loading="lazy"
              className="size-12"
              src={SwipeMachineIcon}
              alt="Swipe machine icon"
            />
            <h3 className="text-xl/relaxed lg:text-2xl/relaxed font-medium">
              {t("home.hardwareFreeOfCharge")}
            </h3>
            <p className="text-base/relaxed lg:text-lg/relaxed text-gray-400">
              {t("home.weProvideYouWithAllPaymentTerminals")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
